@import 'components/global.scss';

.base {

  position: absolute;
  top: 50%;
  left: 10px;
  width: $scale1;
  height: $scale1;
  transform: translateY(-50%);

}

.insideButton {

  left: $scale1;

}

.fill {

  fill: white;
  
}

.alignLeft {

  left: 4px;
  right: auto;

}

.alignRight {

  left: auto;
  right: 10px;

}