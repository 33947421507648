@import 'components/global.scss';

.modal {

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background: black;
  background: rgba(0, 0, 0, 0.7);

  header {

    margin-bottom: $scale;

  }

  p {

    margin-bottom: $scale;

  }

  .modal-content {

    position: relative;
    left: 50%;
    width: 100%;
    top: 45%;
    opacity: 0;
    text-align: left;
    // max-height: $scale17;
    transform: translate(-50%, -50%);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    // overflow-y: auto;

  }

  // .modal-content::-webkit-scrollbar {
  //   width: 6px;
  //   border: white;
  // }

  // /* Track */
  // .modal-content::-webkit-scrollbar-track {
  //   background: white;
  //   border-radius: 24px;
  //   border: white;
  // }

  // /* Handle */
  // .modal-content::-webkit-scrollbar-thumb {
  //   background: #3056d3;
  //   border-radius: 24px;
  //   border: white;
  // }

  .custom {

    font-weight: 700;

  }

  @media (max-width: 299px){

    display: none;

    button {

      &:last-child {

        margin-top: $scale-3;

      }
    }
  }

  @media (min-width: 300px){

    button {

      float: left;
      width: 49%;

      &:last-child {

        float: right;

      }
    }
  }

  @media (min-width: 500px){

    .modal-content {

      max-width: $scale16;

    }
  }

  @media (min-width: 1200px){

    .modal-content {

      max-width: $scale18;

    }

    h1 {
      text-align: left;
    }

    button {
      width: fit-content;
    }
  }
}

.modal-appear {

  opacity: 0;

}

.modal-appear-done {

  opacity: 1;

  .modal-content {

    opacity: 1;
    top: 50%;

  }
}
