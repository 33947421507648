@import 'components/global.scss';

.tabs {

  overflow: hidden;
  border-bottom: 1px solid $c-border;

  @media (min-width: $mobile){

    border-bottom: none;

  }
}

.button {

  position: relative;
  float: left;
  width: 100%;
  padding: $scale $scale5;
  outline: none;
  font-size: 0.85em;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid $c-border;

  @media (min-width: $mobile){

    width: auto;
    border-bottom: none;

  }
}

.buttonInActive {

  color: black;
  font-weight: 600;
  background-color: white;
  border: 1px dashed rgb(221, 221, 221);

  @media (min-width: $mobile){

    background-color: #f4f7fd;

  }
}

.buttonActive {

  color: black;
  font-weight: 700;
  background-color: white;
  border: 1px solid white;

  @media (min-width: $mobile){

    color: $c-text;
    background-color: white;

  }
}
