@import 'components/global.scss';

.base {

  display: block;
  width: $scale10;
  height: $scale4;
  margin: 0 auto;
  outline: none;

  @media (max-width: $mobile){

    width: $scale8;

  }
}
