@import 'components/global.scss';

.company {

  padding: $scale1 $scale1;

}

.companyLink {

  color: $c-text;
  display: inline-block;
  text-decoration: none;
  padding: $scale-3 $scale3;
  border-radius: 6px;


  &.active {

    background-color: rgb(30,78,216);
    color: white;
  
  }
}
